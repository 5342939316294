.CadastroProduto {
  .column {
    float: left;
    width: 50%;
    font-size: 18px;
    font-weight: 300;

    &.last {
      width: 200px;
    }

    &.fifty {
      height: 90vh;
      background-color: #ffffff;
      float: right;
    }
  }

  .form {
    width: 90%;
    padding: 10px 0 0 20px;
  }

  ._15k6ur99 {
    width: 100% !important;
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .box-cadastra-produto {
    box-shadow: 0px 2px 1px 2px #efefef;
    border-radius: 10px;
    margin-right: 30px;
    background-color: #f6f6f6;
  }

  .bottom-button {
    margin-top: 40vh;
    margin-right: 5%;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorSecondary.MuiInputBase-formControl.css-hjtp1-MuiInputBase-root-MuiOutlinedInput-root {
    background: white;
    min-width: 340px;
  }

  div#promptVersionSelect {
    width: 340px;
    .css-13cymwt-control {
      height: 55px;
    }

    .css-1fdsijx-ValueContainer {
      height: 55px;
    }
  }

  .box-upload {
    box-shadow: 0 0 4px 0 #ccc;
    padding: 30px;
  }

  .box-file {
    width: 500px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    padding: 50px;
    box-shadow: 0 0 2px 2px #e5e5e5;
  }

  .custom-file-upload {
    text-align: center;
    align-items: center;
    cursor: pointer;
    padding: 30px;
    border: 3px dashed #660099;
    width: 386px;
    height: 115px;

    &.border-file-none {
      position: absolute;
      margin: 0 auto;
      opacity: 0;
      margin-top: -115px;
      margin-left: -180px;
    }
  }

  .widthSelect {
    width: 340px;
  }


}