.left-title {
  width: 50%;
  float: left;
  font-size: 16px; 
  p {
    line-height: 0.5;  
  }
}

.right-title {
  width: 50%;
  float: left;
  text-align: right;
  margin-top: 20px;

  span {
    font-weight: 600;
    font-size: 14px;  
    color: #660099;
    background-color: #F6ECF6;
    border-radius: 15px;
    padding: 10px;
  }
} 