h3 {
  color: #660099;
}

.parent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.main {
  display: flex;
  flex-grow: 1;
}

.content {
  flex-grow: 1;
  margin-left: 50px !important;
}

.side {
  width: 300px;
  border-right: 1px solid #dddddd;
}

.child {
  width: 300px;
  margin: 5px;
  padding: 10px;
  font-size: 20px;
  line-height: 20px;
}

.top-head-title {
  height: 100px;
}

.top-head-tags {
  padding-top: 27px;
  width: 50%;
  position: absolute;
}

.ctnFlex {
  display: flex;
  justify-content: space-between;
  color: #660099;
}

div.op1 {
  width: 50%;
  display: inline-block;
}

div.op2 {
  display: inline-block;
  margin-right: 0vw;
  text-align: right;
  padding-top: 20px;
}

.sub-title-tag {
  font-size: 18px;
  font-weight: 400;
  min-width: 100px;
  float: left;
}

.span-filtro {
  color: #86888c;
  float: left;
  padding-top: 10px;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 15px;
}

.text-tag {
  vertical-align: top;
}

.icon-tag {
  vertical-align: middle;
}

.both {
  clear: both;
}

.box-tag {
  vertical-align: text-top;
  height: 15px;
  padding: 5px 10px 10px 10px;
  border-radius: 20px;
  background-color: #efe5f4;
  color: #660099;
  float: left;
  max-width: 350px;
  margin-right: 10px;
  font-size: 14px;
  &.color-blue {
    background-color: #e6f5fd;
    color: #019df4;
  }
}

.bottom-space {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 300px !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 220px !important;
  z-index: 0;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium.MuiButton-root.MuiButton-outlined.MuiButton-outlinedSecondary.MuiButton-sizeMedium.MuiButton-outlinedSizeMedium {
  border: 1.5px solid;
  color: #660099 !important;
  text-transform: initial !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary.MuiButton-sizeMedium.MuiButton-containedSizeMedium {
  background: #660099 !important;
  text-transform: initial !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAccordion-root.MuiAccordion-rounded.MuiAccordion-gutters {
  border: #dddddd 1px solid;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: none;
  position: unset;
}

.btnSearch {
  height: 52px;
  width: 50px;
  float: right;
}

.btnClear {
  float: right;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButton-root.MuiButton-contained.MuiButton-containedSecondary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.btnClear {
  margin-left: 5px;
}

.btnSearch > *:nth-of-type(1) {
  padding-left: 15px;
}

/* .css-11yukd5-MuiTabs-indicator {
  background-color: #660099 !important;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #000000 !important;
} */

/* .MuiTabs-flexContainer > *:nth-of-type(1) {
  color: #000000 !important;
} */

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary.Mui-selected{
  color: #000000 !important;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary {
  text-transform: none !important;
  font-size: 18px !important;
  font-weight: 500 !important;;
}

.bg-colum-vivo-melhor {
  color: #660099 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  background-color: #f6ecf6;
}

.bg-colum-concorrencia {
  color: #019df4 !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  background-color: #e6f5fd;
}

.rmsc {
  --rmsc-main: none !important;
  --rmsc-hover: none !important;
  --rmsc-selected: none !important;
  --rmsc-border: none !important;
  --rmsc-gray: #0b2739 !important    ;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 48px; /* Height */

  border: #dddddd 1px solid;
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: none;
  position: unset;
  font-size: 18px !important;
}

.dropdown-content input[type="checkbox"] {
  accent-color: #660099;
  width: 18px;
  height: 18px;
}

.rmsc .dropdown-container {
  border: none !important;
}

.rmsc .dropdown-heading .dropdown-heading-value {
  padding: 1px;
}

#botMessage div table,

tr {
 border-left: 2px solid #ebebeb !important;
 border-bottom: 2px solid #ebebeb !important;
 border-right: 2px solid #ebebeb !important;
}
.MuiTable-root {
  border-collapse: separate;
  border: 2px solid #ffffff !important; 
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter.MuiTableCell-sizeMedium {
  font-weight: 600;
  border: 2px solid #ffffff !important; 
}

.MuiTableCell-head {
  border-radius: 0  !important;
}

.MuiTableCell-head:first-child {
  border-radius: 15px 0 0 0  !important;
}


.MuiTableCell-head:last-child {
  border-radius: 0 15px 0  0    !important;
}

.css-1a4cg4j-MuiButtonBase-root-MuiTab-root {
  font-size: 18px !important;
  text-transform: none !important;
  font-weight: 500 !important;;
}

.MuiBox-root.css-19kzrtu {
  padding: 60px 0 !important;
}

.customDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
  border-color: #660099 !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #660099 !important;
}

.back-head {
  font-size: 14px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.txt-preco-vivo {
  color: #660099 !important;
}

.pagination {
  float: right;

  .numbers {
    border: 1.5px solid #660099;
    color: #660099;
    font-weight: 600;
    border-radius: 20px;
    padding-top: 7px;
    display: inline-block;
    height: 31px;
    width: 40px;
    margin: 10px;
    text-align: center;
    font-size: 15px;
  }
  span.numbers.current {
    color: #ffffff;
    background-color: #660099;
  }
}

.centraliza-loader-table {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.hide-arrow {
  display: none !important;
}

span.preco_prazo {
  width: 90px !important;
  display: inline-block;
}

.tableAtualizacoes {
  overflow-x: scroll;
  overflow-y: hidden;
}

.customStyleTab{
  display: flex;
}

.legenda {
  .m_preco {
    width: 135px;
    float: left;
    margin-top: 10px;
}

  .leg_vivo_melhor {
    width: 20px;
    height: 20px;
    border-radius: 40px;
    background-color: #F6ECF6;
    color: #F6ECF6;
    float: left;
    margin-right: 5px;
  }
  .leg_concorrencia {
    width: 20px;
    height: 20px;
    border-radius: 40px;
    background-color: #E6F5FD;
    color: #E6F5FD;
    margin-right: 5px;
    margin-left: 20px;
    float: left;
  }
}

.icon_vivo_loader{
  position: absolute;
}


button.MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
  padding: 10px !important;;
  margin-right: 10px !important;;
  text-transform: initial !important;;
  font-size: 18px !important;;

  &.active  {
    border-bottom: 2px solid #660099;
  }
}

.checkClick {
  width: 20px !important;
  height: 10px !important;
  z-index: 9 !important;
  top: 15px !important;
  left: 230px !important;
  position: relative;
}

.multSelect {
  position: absolute !important;
}

.MuiTabs-scroller {
  overflow: auto !important
}

.MuiTabs-scroller::-webkit-scrollbar {
  height: 7px !important;;
}
.MuiTabs-scroller::-webkit-scrollbar-thumb {
  border-radius: 50px !important;;
}

.hide-table{
  display: none;
}

.show-table{
  display: inline;
}


.btnSearchPagination {
  min-width: 35px !important;
}

.txtInputPagination {
  font-size: 20px;
}

.inputSearchPagination {
  width: 40px;
  border: 1.5px solid #660099;
  border-radius: 10px;
  padding: 7px;
  margin-right: 10px;
  font-size: 16px;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm {
  min-width: 720px !important;
}

.floatLoaderRanking {
  clear: both;
}

.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar {
  height: 7px;
}
.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar-thumb {
  border-radius: 50px;
}
.css-jpln7h-MuiTabs-scroller::-webkit-scrollbar-track {
}

.msg-wrapper {
  white-space: pre-wrap;
}

@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 992px) {
}
@media (min-width: 1200px) {
}
@media (min-width: 1400px) {
}
