header {
  align-items: center;
  background-color: white;
  box-shadow: 0 0 4px 0 #ccc;
  display: flex;
  height: 51px;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;
}

.profile {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.menu-button-container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 50px;
}
.menu-button {
  align-items: center;
  background-color: white;
  border: 0px solid black;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  padding: 0px;
  width: 40px;
  cursor: pointer;
}
.menu-button.active {
  background-color: #f2f2f2;
}

.header-title {
  font-size: 33px;
  font-weight: 100;
}

@media (min-width: 768px) { }
@media (min-width: 992px) { }
@media (min-width: 1200px) { }
@media (min-width: 1400px) { }

.header-right-align {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding: 16px 0;
}

.header-avatar-user {
  width: 24px;
  height: 24px;
  margin: 0 15px;
  cursor: pointer;
}

.header-user-name {
  cursor: pointer;
}

.header-user-name-profile {
  margin-left: 4px;
  cursor: pointer;
  font-size: 13px;
  color: #c8c8c8
}

@media (min-width: 576px) {
  .header-user-name {
      display: block;
  }
}

.header-avatar-options {
  display: none;
  position: absolute;
  top: 30px;
  right: 15px;
  box-shadow: 0 0 15px 0 #ababab;
  min-width: 200px;
  border-radius: 2px;
  overflow: hidden;
  padding: 0;
  z-index: 1;
}

.header-right-align:hover > .header-avatar-options, .header-avatar-options:hover {
  display: block;
}

.header-avatar-options > li {
  display: flex;
  align-items: center;
  min-height: 36px;
  padding: 0px 12px;
  font-size: 14px;
  background-color: #fff;
  cursor: pointer;
}
.header-avatar-options > li a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: black;
  width: 100%;
}
.header-avatar-options > li svg {
  margin-right: 10px;    
}
.header-avatar-options > li:hover, .header-avatar-options > li:hover > a {
  background-color: #f2f2f2;
  color: #660099;
}