.CompetitividadeDetalhes {
  .column {
    float: left;
    width: 33.33%;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;

    

    &.last {
      width: 200px;
      margin-left: 30px;
      line-height: 1.5;
    }

    &.fifty {
      width: 65%;      
    }

    span {
      clear: both;
      margin: 5px 0 5px 0;
      float: left;
    }
  }

  .box-tag-competividade {
    height: 10px;
    padding: 0px 20px 20px 19px;
    margin: 10px 0 0px;

    vertical-align: text-top;

    border-radius: 20px;
    background-color: #efe5f4;
    color: #660099;
    float: left;
    max-width: 350px;

    font-size: 14px;
    &.color-blue {
      background-color: #e6f5fd;
      color: #019df4;
    }
  }

  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .box-media-preco {
    border: 1px solid #660099;
    border-radius: 8px;
    padding: 0 30px 30px;
    width: 300px;
    text-align: center;
    vertical-align: middle;
  }

  .box-chart {
    box-shadow: 0px 2px 1px 2px #efefef ;
    border-radius: 10px;
    margin-right:30px;
  }

  
}

a:link  {
  color: #660099;
  text-decoration: none;
  cursor: pointer;
}

.link {
  color: #660099;
  text-decoration: none;
  cursor: pointer;
}

/* visited link */
a:visited {
  color: #660099;
}

/* mouse over link */
a:hover {
  color: #440165;
}


