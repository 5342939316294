.CadastraCep {

  .box-cadastra-cep {
    box-shadow: 0px 2px 1px 2px #efefef;
    border-radius: 10px;
    margin-right: 30px;
    background-color: #F6F6F6;
    padding: 10px 30px 25px;
    width: 340px;
    margin-top: 50px;
  }

  .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    background: white;
    width: 340px;
  }

  .box-file {
    width: 500px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
    padding: 50px;
    box-shadow: 0 0 2px 2px #e5e5e5;
  }


}
