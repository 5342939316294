.container-principal {
  display: flex;
  background-color: #f7f7f7;
  flex-direction: row;
  height: 100%;
  width: 100% !important;
}
/* header {
    display: none;
  } */
header h1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #d9d9e3;
  margin: auto;
}

nav {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
}
.navItems {
  padding: 8px;
  background-color: #212122;
  width: 100%;
  max-width: 340px;
}
.navPromptWrapper {
  border-bottom: 1px solid #d9d9d9;
  padding: 10px;
  height: calc(100vh - 330px);
  overflow-y: auto;
}
.navPrompt {
  display: flex;
  align-items: center;
  padding: 10px;
  column-gap: 20px;
  margin: 5px 0;
  cursor: pointer;
  text-align: left;
}
.navPrompt a {
  display: flex;
  align-items: center;
  column-gap: 20px;
  text-decoration: none;
}
.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #979db7;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}

.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}
#botMessage pre {
  margin: 0;
  padding: 0;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;
  top: -45px;
  font-weight: 400;
}
#botMessage div table,
th,
td {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  border-collapse: collapse;
  padding-right: 10px;
  padding-left: 5px;
  cursor: pointer;
}
pre {
  font-family: var(--text-font);
}
@keyframes fadeInChar {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.navCloseIcon {
  width: 20%;
  margin: 10px;
}
nav svg {
  float: left;
}
.sideMenu {
  width: 244px;
  height: 92vh;
  padding: 8px;
  background-color: #fff;
}
.sideMenuIcon {
  color: #650098;
}
.sideMenuButton {
  color: #979db7;
  border: 1px solid #650098;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 18px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  font-weight: 500;
}
.sideMenuButton:hover {
  cursor: pointer;
  background-color: hsla(240, 9%, 59%, 0.1);
}
.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}
.chatBox {
  flex: 1;
  position: relative;
  line-height: 24px;
  color: #000;
  font-size: 16px;
  width: 100%;
  height: 90vh;
}
#introsection::before,
#introsection::after {
  float: left;
  color: rgb(0, 134, 244);
}
#introsection {
  text-align: left;
  padding: 20px;
}
#introsection h1 {
  line-height: 33px;
}
#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}
#introsection ul {
  list-style-type: square;
}
#introsection pre {
  margin: 0;
  display: inline;
}
.chatLogWrapper {
  height: 80%;
  margin-top: 60px;
  overflow-y: auto;
}

.chatLogWrapperUpload {
  height: 80%;
  width: 100%;
  margin-top: 60px;
  overflow-y: auto;
}

.accordionItemWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  width: max-content;
}

.accordionWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 800px;
  margin: auto
}
.accordion {
  width: 57%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 6px !important;
  background-color: none;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #575969;
}
::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(243, 241, 241);
}

.chatPromptWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}

.userSVG {
  color: #fff;
  transform: scale(0.6);
}
.botMessageMainContainer {
  width: 100%;
  background-color: #f3f3f3;
  position: relative;
}

.text-pattern {
  font-weight: 400;
  font-size: 18px;
}
.botMessageWrapper {
  max-width: 800px;
  margin: auto;
  padding: 24px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  font-weight: 400;
  font-size: 18px;
  column-gap: 25px;
}
.stop-messgage {
  position: absolute;
  bottom: 10px;
  right: 100px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10%;
  border: 1px solid rgb(86, 88, 105);
  background-color: rgb(52, 53, 65);
  color: rgb(217, 217, 217);
  cursor: pointer;
}
.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  /* border: none; */
}
.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}
#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}
.inputCustomPromptWrapper {
  top: 60px;
  z-index: 999;
  height: 40px;
  width: 95%;
  position: absolute;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.icon-arrow {
  color: #650098;
}

.icon-arrow-modal {
  color: #650098;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 16px;
}

.tips {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 70%;
  margin: auto;
  margin-top: 30px;
  gap: 16px;
}

.tips-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title-intro {
  font-size: 32px;
  font-weight: 400;
  margin-right: auto;
  margin-left: 48px;
  text-align: start;
}

.tips-item {
  background-color: #f3f3f3;
  color: #5a5757;
  font-size: 14px;
  max-width: 326px;
  display: flex;
  text-align: center;
  padding: 12px;
  height: 120px;
  margin-top: 20px;
}

h2 {
  color: #650098;
  font-weight: 500;
}

.ant-image {
  margin: auto !important;
}

.icons-intro {
  width: 45px;
  height: 45px;
}

.dropdown {
  width: 150px;
  height: 50px;
  border: 1px solid #650098;
  border-radius: 6;
}

.dropdown:hover {
  width: 150px;
  height: 50px;
  border: 1px solid #650098 !important;
  color: #650098 !important;
  background-color: #ffffff;
  border-radius: 0;
}

.inputPromptWrapper {
  position: absolute;
  margin: auto;
  left: 12%;
  bottom: 3%;
  width: 75%;
  max-height: 200px;
  height: 55px;
  background-color: #f7f7f7;

  display: flex;
}
.dd-wrapper {
  resize: none;
  font-size: 10px;
  outline: none;
  border: none;
  display: table-cell;
}
.dd-header {
  height: 55px;
  font-size: 10px;
}
.dd-header-title {
  font-size: 16px;
}
.dd-list-item {
  font-size: 16px;
}
.inputPrompttTextarea {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  flex: 1;
  resize: none;
  background-color: #fff;
  font-size: 16px;
  outline: none;
  border-color: #eee;
  border-width: 1px;
  border-style: solid;
  height: 55px;
}
.button-form-chat {
  background: #fff;
  border: none;
  position: absolute;
  top: 10%;
  right: 15px;
  width: 35px;
  height: 45px;
}

.loginContainer {
  background: var(--primary-bg-color);
  width: 100%;
  height: 100%;
  font-family: var(--text-font);
}

.loginContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.loginContainerContent svg {
  padding-bottom: 30px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0;
}

.loginContainer p {
  font-size: 18px;
  line-height: 5px;
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 20px;
}

#loginButton {
  padding: 10px 25px;
  border-radius: 7px;
  color: #fff;
  background: #10a37f;
  border: none;
  font-size: 16px;
  cursor: pointer;
}
#loginButton:hover {
  background-color: rgb(26, 127, 100);
}

@media screen and (max-width: 450px) {
  .button-file {
    transform: translate(-20px, 350%);
  }
}
.intro-section-container {
  padding-top: 60px; 
}
@media screen and (max-width: 1024px) {
  .button-file {
    margin-bottom: 110px !important;
    left: 32%;
  }
  .App {
    display: block;
  }
  header {
    display: flex;
    align-items: center;
    padding: 4px 0 0 12px;
  }
  nav {
    display: flex;
  }
  .sideMenu {
    display: none;
  }
  .chatBox {
    position: static;
    margin-top: 50px;
  }
  .chatPromptWrapper {
    padding: 12px;
  }
  .botMessageWrapper {
    padding: 12px;
  }
  .stop-messgage {
    color: #d9d9d9;
    right: 5px;
    font-size: 13px;
    padding: 8px 15px;
  }
  .userSVG {
    transform: scale(0.5);
  }
  .openaiSVG {
    transform: scale(0.4);
  }
  #avatar {
    width: 30px;
    height: 30px;
  }
  #introsection {
    padding: 20px 20px 40px 20px;
    font-size: 14px;
  }
  #introsection h1,
  pre {
    font-size: 16px;
    white-space: pre-wrap;
  }
  #introsection h2 {
    font-size: 14px;
  }
}

@media screen and (min-width: 1536px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .loginContainer p {
    font-size: 25px;
  }
  #loginButton {
    font-size: 22px;
  }
}

.button-file {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 180px;
  height: 80px;
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  color: #5a5757;
  font-weight: 400;
  margin: 18px;
  margin-right: 75px;
  cursor: pointer;
}
.button-file:hover {
  background-color: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 10px;
}

.attach-icon {
  background-color: #660099;
  color: white;
  width: fit-content;
  height: fit-content;
  padding: 8px;
  border-radius: 100%;
}

.container-attach-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.files-description {
  color: #979db7;
}

.modal {
  flex-direction: column;
  display: flex;
  text-align: center;
}

.MuiAccordionDetails-root accordionDetails css-15v22id-MuiAccordionDetails-root {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.input-file-regenerate {
  text-decoration: none;
  background-color: #650098;
  height: 40px;
  width: 200px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  display: flex;
  border-radius: 10px;
  margin-top: 24px;
  cursor: pointer;
  margin-left: 50;
}

.input-file-regenerate[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.input-file-regenerate-download {
  text-decoration: none;
  background-color: #650098;
  height: 40px;
  width: 300px;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  display: flex;
  border-radius: 10px;
  margin-top: 96px;
  cursor: pointer;
  margin-right: 0;
  margin-left: auto;
}

.input-file {
  text-decoration: none;
  background-color: #650098;
  height: 40px;
  width: 335px;
  margin: auto;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  display: flex;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 24px;
}

.input-file-text {
  margin: auto;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.inputs-modal {
  width: 100%;
  border-bottom: 1px solid #d9d9d9;
  padding-bottom: 16px;
}

.dropzone {
  width: 95%;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
} 
.flex-description {
  border:#5a5757 1px dotted;
  max-width: 500px;
  margin-top: 15%;
  padding: 10px;
  margin-left: -12px;
  font-size: 18px;
  font-weight: 400;
}