@font-face {
  font-family: Telefonica;
  src: local(Telefonica-ExtraLight),
    url(assets/font/Telefonica/ExtraLight/Telefonica-ExtraLight.woff2) format("woff2"),
    url(assets/font/Telefonica/ExtraLight/Telefonica-ExtraLight.woff) format("woff"),
    url(assets/font/Telefonica/ExtraLight/Telefonica-ExtraLight.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Telefonica;
  src: local(Telefonica-Light),
    url(assets/font/Telefonica/Light/Telefonica-Light.woff2) format("woff2"),
    url(assets/font/Telefonica/Light/Telefonica-Light.woff) format("woff"),
    url(assets/font/Telefonica/Light/Telefonica-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Telefonica;
  src: local(Telefonica-Regular),
    url(assets/font/Telefonica/Regular/Telefonica-Regular.woff2) format("woff2"),
    url(assets/font/Telefonica/Regular/Telefonica-Regular.woff) format("woff"),
    url(assets/font/Telefonica/Regular/Telefonica-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Telefonica;
  src: local(Telefonica-Bold),
    url(assets/font/Telefonica/Bold/Telefonica-Bold.woff2) format("woff2"),
    url(assets/font/Telefonica/Bold/Telefonica-Bold.woff) format("woff"),
    url(assets/font/Telefonica/Bold/Telefonica-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Telefonica-Cap;
  src: local(Telefonica-Cap),
    url(assets/font/Telefonica/Cap/TelefonicaCap-Regular.woff2) format("woff2"),
    url(assets/font/Telefonica/Cap/TelefonicaCap-Regular.woff) format("woff"),
    url(assets/font/Telefonica/Cap/TelefonicaCap-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

html,
body {
  font-family: 'Telefonica';
  height: 100%;
  margin: 0;
}

#aria-id-hook-2 {
  width: 160px !important;
  border-radius: 10px;
}

.mistica-10-29-0_1_border-0-3-66 {
  width: 100%;
}

.scroll .active {
  border-left-color: #660099;
}

p {
  font-family: Telefonica !important;
}

.pre-wrap {
  white-space: pre-wrap;
  word-break: break-word;
  padding: 0 6.25rem 1.25rem 3.125rem;
}