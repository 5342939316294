.sidebar {
    height: 100%;
    width: 51px;
    overflow-x: hidden;
    transition: .5s;
    background: #fff;
    display: block;
    top: 51px;
    left: 0;
    position: fixed;
    box-shadow: 0 4px 4px 0 #ccc;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    z-index: 5;
    border-top: solid #ccc;
    border-width: 1px;
}
.sidebar.active {
    width: 220px;
}

.sidebar:not(.active) .sidebar-item{
    min-height: 43px; 
}
.sidebar-item {
    position: relative;
    padding-left: 15px;
    cursor: pointer;
    line-height: 42px;
    margin-bottom: 10px;
    margin-top: 5px;
    display: block;
    box-sizing: border-box;
    transition: 0.5s;
}

.sidebar-item:hover { border-right: 3px solid #660099; }
.sidebar-item:hover .img-blue{ display: inline-block; }
.sidebar-item:hover .img-gray{ display: none; }

.sidebar-item.current { 
    background-color: #f2f2f2; 
}
.sidebar-item.current .img-blue{ display: inline-block; }
.sidebar-item.current .img-gray{ display: none; }

.img-blue:not(.sub-item-img), .img-gray:not(.sub-item-img) {
    margin-left: 13px;
    /* cursor: default; */
}
.img-gray{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    float: left;
    height: 21px;
    margin-top: 10px;
    position: absolute;
    width: 23px;
}

.img-blue{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: none;
    float: left;
    /* height: 21px; */
    margin-top: 10px;
    position: absolute;
    width: 23px;
}


.sidebar .side-span{
    display: inline-block;
    font-size: 16px;
    margin: 0 1px;
    font-weight: 600;
    transition: .5s;
    color: #86888c;
    min-width: 180px;
    color: black;
    box-sizing: border-box;
    background-color : white;
}

.sidebar:not(.active) .sidebar-item:not(.current):hover .side-span{
    display: inline-block;
}
.sidebar:not(.active) .sidebar-item:not(.current) .side-span{
    display: none;
    position: fixed;
    left: 50px;
    border-radius: 2px;
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.2);
    min-width: 180px;
}

.sidebar:not(.active) .sidebar-item.current .side-span p, 
.sidebar:not(.active) .sidebar-item.current .side-span .sub-item-title {
    color: #f2f2f2;
}
.sidebar:not(.active) .sidebar-item.current .side-span .side-sub-item:hover p, 
.sidebar:not(.active) .sidebar-item.current .side-span .side-sub-item:hover .sub-item-title {
    color: #e5e5e5;
}


.sidebar-item:hover .side-span{ display: inline-block; }

.side-span p, .external-span p{
    padding: 0px 12px;
    margin: 0;
    cursor: pointer;
}

.side-span ul, .external-span ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.sidebar.active .side-span ul {
    display: none;
    transition: 0.5s;
}
.sidebar:not(.active) .sidebar-item.current .side-span ul{
    display: block;
    transition: 0.5s;
}

.side-span ul.void {
    display: none;
}
.sidebar.active .sidebar-item.open .side-span ul {
    display: block;
}
.sidebar.active .sidebar-item.open {
    background-color: #f2f2f2;
}
.sidebar.active .sidebar-item.open .side-span {
    background-color: #f2f2f2;
}

.side-sub-item{
    font-size: 14px;
    font-weight: 400;
    padding: 0px 12px;
    position: relative;
}

.side-sub-item:hover{
    color: #660099;
    background-color: #ededed;
}

.side-span .side-sub-item.open {
    background-color: #e0e0e0;
}
.sidebar:not(.active) .side-span .side-sub-item.open .sub-item-title{
    color: #e5e5e5 !important;
}

.external-span {
    font-size: 16px;
    margin: 0 1px;
    font-weight: 600;
    transition: .5s;
    color: #86888c;
    width: 240px;
    color: black;
    box-sizing: border-box;
    background-color : white;
    display: none;
    position: fixed;
    left: 218px;
    border-radius: 2px;
    box-shadow: 0px 0px 4px 2px rgba(0,0,0,0.2);
    min-width: 180px;
}

.sidebar.active .sidebar-item:not(.open):hover .external-span {
    display: inline-block;
}

.external-span img, .sidebar-item:not(.current) .side-span img {
    /* display: none !important; */
}

.sidebar:not(.active) .sidebar-item.current {
    pointer-events: none;
}
.sidebar:not(.active) .sidebar-item.current .external-span,
.sidebar:not(.active) .sidebar-item.current .side-span p,
.sidebar:not(.active) .sidebar-item.current .side-span .side-sub-item  {
    pointer-events: auto;
}

.sidebar:not(.active) .sidebar-item.current .side-span{
    background-color: #f2f2f2;
}

.sidebar.active img {
    position: relative;
}

.sub-item-img {
    /* height: 18px !important; */
    /* margin-left: 5px; */
}

.sidebar:not(.active) .sub-item-title {
    margin-left: 35px;
    display: inline-block;
}

.sidebar.active .sub-item-title {
    margin-left: 5px;
    display: inline-block;
}

.sub-external {
    display: none;
}

.sidebar:not(.active) .sidebar-item.current .sub-external {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 2px;
    border-left: 3px solid #660099;
    color: black;
    display: none;
    font-size: 16px;
    left: 50px;
    margin: 0 1px;
    min-width: 180px;
    padding: 0px 12px;
    position: fixed;
    transition: .5s;
}

.sidebar.active .sidebar-item.open.current .img-blue:not(.sub-item-img), 
.sidebar.active .sidebar-item.open.current .img-gray:not(.sub-item-img) {
    position: absolute;
}

.sidebar.active .sidebar-item.open.current .side-span {
    width: calc(100% - 15px);
}

.sidebar.active .sidebar-item.open.current .side-span p{
    margin-left: 35px;
}

.sidebar.active .sidebar-item.open.current .side-span .side-sub-item{
    padding-left: 35px;
}